.nav-bar {
  width: 100%;
  height: 70px;
  background-color: #2f3542;
}

.branding {
  float: left;
  display: flex;
  align-items: center;
  height: 70px;
}

.logo {
  color: #ffffff;
  font-size: 20pt;
  font-weight: 700;
  margin-left: 10px;
}

.menu-items {
  float: right;
  display: flex;
  align-items: center;
  height: 70px;
}

.nav-bar a {
  color: #ffffff;
  text-decoration: none;
}

.nav-bar a:hover {
  text-decoration: underline;
}

.content-wrapper {
  height: calc(100vh - 110px);
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-wrapper button {
  height: 40px;
  background-color: #2f3542;
  color: #ffffff;
  font-size: 14pt;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
}

.content-wrapper button:hover {
  cursor: pointer;
}

.content-wrapper a {
  color: #2f3542;
  text-decoration: none;
}

.content-wrapper a:hover {
  text-decoration: underline;
}